import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import lostStyles from '../styles/404.module.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title={'404: Not found'} />
    <div className={lostStyles.container}>
      <div style={{ position: 'absolute', top: 0, left: 0 }}>
        <Header />
      </div>
      <h1 className={lostStyles.textHeader}>404: NOT FOUND</h1>
      <p className={lostStyles.textBody}>Seems that page doesn't exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
