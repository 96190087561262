import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Header = () => {
  let { logoImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "major-mazer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return (
    <header
      style={{
        background: '#FE7676'
      }}
    >
      <Link to={'/'}>
        <div style={{ padding: '1rem' }}>
          <Img
            alt={'Major Mazer Logo'}
            fadeIn={false}
            fluid={logoImage.childImageSharp.fluid}
            placeholderStyle={{ opacity: 0 }}
            style={{
              width: '10rem',
              height: 'auto'
            }}
          />
        </div>
      </Link>
    </header>
  );
};

export default Header;
